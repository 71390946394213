import React, { useState, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import MyPinnages from './components/MyPinnages.js';
import Explore from './components/Explore.js';
import WalletConnect from './components/WalletConnect.js';
import Home from './components/Home.js';
import GroupDetails from './components/GroupDetails.js'; // Import GroupDetails component
import Privacy from './components/Privacy.js';
import './App.css';

function App() {
  const [isConnecting, setIsConnecting] = useState(false);
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [userAddress, setUserAddress] = useState('');
  const [isWalletConnected, setIsWalletConnected] = useState(false);

  const handleWalletConnected = useCallback(async (newProvider, newSigner) => {
    console.log("handleWalletConnected - Start");
    setIsConnecting(true);
    try {
      const address = await newSigner.getAddress();
      setProvider(newProvider);
      setSigner(newSigner);
      setUserAddress(address);
      setIsWalletConnected(true);
      console.log("Wallet connected: ", address);
    } catch (error) {
      console.error("Error fetching wallet details:", error);
      setIsWalletConnected(false);
    } finally {
      setIsConnecting(false);
      console.log("handleWalletConnected - Finished");
    }
  }, []);

  const handleWalletDisconnect = useCallback(() => {
    console.log("Disconnecting wallet");
    setUserAddress('');
    setIsWalletConnected(false);
    setProvider(null);
    setSigner(null);
  }, []);	

  return (
    <div className="App">
      <header className="App-header">
        <div className="title-container">
          <Link to="/" title="Go to Home page">Pinnages</Link>
        </div>
        <div className="aggregator-text">A Pinned Messages Aggregator For Telegram Groups</div>
	<nav className="navigation-buttons">
          <Link to="/my-pinnages" className={!isWalletConnected ? "disabled-link" : ""}>My Pinnages</Link>
	  <WalletConnect
            onConnected={handleWalletConnected}
            setIsConnecting={setIsConnecting}
            isConnecting={isConnecting}
            isConnected={isWalletConnected}
            userAddress={userAddress}
            onUserAddressSet={setUserAddress}
            onDisconnect={handleWalletDisconnect}
          />
          <Link to="/explore" className={!isWalletConnected ? "disabled-link" : ""}>Explore Groups</Link>
	</nav>
      </header>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/explore" element={<Explore />} />
        <Route path="/my-pinnages" element={<MyPinnages />} />
        <Route path="/group/:groupName" element={<GroupDetails />} /> {/* New Route for GroupDetails */}
        <Route path="/privacy" element={<Privacy />} /> 
	</Routes>      
    </div>
  );
}

export default App;

import React from 'react';
import './Privacy.css'; // Reuse the Explore.css if it fits the styling needs

const Privacy = () => {
    return (
        <div className="explore-container">
            <h1 className="explore-header">Privacy Policy</h1>
            <div className="privacy-content">
                <p>Last updated: March 15, 2024</p>
                
                <h2>Introduction</h2>
                <p>At Pinnages, we are committed to protecting the privacy and security of our users. This Privacy Policy outlines the types of information we collect from our users, how we use it, and the measures we take to protect it.</p>

                <h2>Information Collection and Use</h2>
                <p>We collect information to provide better services to our users. This includes information that you provide directly when you register for an account, such as your name, email address, and telephone number. We also collect information about how you use our services, like the types of content you view or interact with or the frequency and duration of your activities.</p>

                <h2>Cookies and Tracking Technologies</h2>
                <p>We use cookies and other tracking technologies to track the activity on our Service and hold certain information. Cookies are files with a small amount of data which may include an anonymous unique identifier. We use cookies to collect and store information to improve our services for you and to provide a more personalized experience on our app.</p>

                <h2>Data Sharing and Disclosure</h2>
                <p>We do not share or sell your personal data with third parties except to provide the services you've requested, when we have your permission, or under the following circumstances:</p>
                <ul>
                    <li>We may share information with our trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries.</li>
                    <li>If Pinnages is involved in a merger, acquisition, or sale of all or a portion of its assets, your personal information may be transferred as part of the deal.</li>
                    <li>We will disclose your personal information, without notice, only if required to do so by law or in the good faith belief that such action is necessary to comply with legal processes, protect and defend the rights or property of Pinnages, and act under exigent circumstances to protect the personal safety of users of Pinnages, or the public.</li>
                </ul>

                <h2>Data Security</h2>
                <p>We value your trust in providing us your personal information and strive to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>

                <h2>Your Rights</h2>
                <p>You have the right to access, update, or delete your personal information at any time. If you wish to exercise any of these rights, please contact us. We also respect your right to privacy and will comply with requests to unsubscribe from our marketing communications.</p>

                <h2>GDPR Compliance</h2>
                <p>We are committed to ensuring that our service complies with the General Data Protection Regulation (GDPR). If you are a resident of the European Economic Area (EEA), you have certain data protection rights covered under GDPR. We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.</p>

                <h2>Children's Privacy</h2>
                <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers.</p>

                <h2>Changes to This Privacy Policy</h2>
                <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

                <h2>Contact Us</h2>
                <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at support@pinnages.com.</p>
            </div>
        </div>
    );
};

export default Privacy;


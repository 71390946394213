import React, { useState, useEffect, useRef } from 'react';
import JWTHandler from './JWTHandler.js';
import WalletConnect from './WalletConnect.js';

// Timeout Handling for Fetch Requests
const fetchWithTimeout = (resource, options = {}) => {
    const { timeout = 8000 } = options; // Default timeout of 8 seconds
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    return fetch(resource, {
        ...options,
        signal: controller.signal
    })
    .finally(() => clearTimeout(id));
};

const MyPinnages = () => {
    const [apiId, setApiId] = useState('');
    const [apiHash, setApiHash] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [telegramCode, setTelegramCode] = useState('');
    const [walletConnected, setWalletConnected] = useState(false);
    const [userAddress, setUserAddress] = useState('');
    const walletConnectRef = useRef();
    const [provider, setProvider] = useState(null); // State for provider
    const [signer, setSigner] = useState(null); // State for signer
    const [is2FAInputActive, setIs2FAInputActive] = useState(false);

    const checkTokenAndWallet = () => {
        if (!JWTHandler.getToken() || !JWTHandler.validateToken()) {
            if (!walletConnected && walletConnectRef.current) {
                walletConnectRef.current.initiateWalletConnection();
            }
        }
    };

    useEffect(() => {
        checkTokenAndWallet();
        if (walletConnected) {
        }
    }, [walletConnected, userAddress]);

    const handleWalletConnected = (newProvider, newSigner) => {
        setProvider(newProvider);
        setSigner(newSigner);
        newSigner.getAddress().then(address => {
            setUserAddress(address);
            setWalletConnected(true);
        }).catch(error => {
            console.error('Error getting address:', error);
            setWalletConnected(false);
        });
    };

    const validatePhoneNumber = (number) => {
        const internationalPhoneRegex = /^\+\d{7,15}$/;
        return internationalPhoneRegex.test(number);
    };

    const handle2FACodeInput = async () => {
	setIs2FAInputActive(true);
        let enteredCode = '';
        const timeoutId = setTimeout(() => {
            if (is2FAInputActive) {
	        console.warn('2FA input timed out.');	    
                alert('Time limit exceeded, please try again.');
                setIs2FAInputActive(false);
            }
        }, 30000); // 30 seconds

        enteredCode = prompt('Enter the Telegram verification code (you have 30 seconds):');

    if (userAddress) { // Check if userAddress is set
    } else {
        console.warn('User address not set');
        alert('User address not available. Please ensure wallet is connected.');
        return;
    }

        clearTimeout(timeoutId);
        if (enteredCode && is2FAInputActive) {
	    setIs2FAInputActive(false);
            await store2FACodeInDynamoDB(enteredCode);
        } else if (!enteredCode) {
            console.warn('No 2FA code entered.');          
	    alert('No code entered, please try again.');
        }
    };

    const store2FACodeInDynamoDB = async (code) => {
        const maxRetries = 3;
        let attempt = 0;

        while (attempt < maxRetries) {
            try {
                const start = Date.now(); // Start time for the request
                const response = await fetchWithTimeout('https://dhtet9q3v6.execute-api.us-east-1.amazonaws.com/prod/submit-code', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ WalletAddress: userAddress, Code: code }),
                    timeout: 10000, // 10 seconds timeout
                });

                const end = Date.now(); // End time for the request

                if (response.ok) {
                    const responseData = await response.json();
                    alert('2FA Code submitted successfully.');
                    return true;
                } else {
                    const errorResponse = await response.json();
                    console.error(`Error storing 2FA code: ${errorResponse.message}`);
                    alert(`Error: ${errorResponse.message}`);
                }
            } catch (error) {
                console.error('Attempt to store 2FA code failed:', error);
                alert(`Attempt to store 2FA code failed: ${error.message}`);
            }
            attempt++;
            await new Promise(resolve => setTimeout(resolve, 2000)); // Delay before retry
        }

        console.error('Failed to submit 2FA Code after several attempts.');
        alert('Failed to submit 2FA Code after several attempts. Please try again.');
        return false;
    };

    const handleApiCredentialsSubmit = async (e) => {
        e.preventDefault();
        
	if (!validatePhoneNumber(phoneNumber)) {
            alert('Please enter a valid phone number in international format.');
            return;
        }

        if (!walletConnected || !userAddress) {
            alert('Please connect your wallet first.');
            return;
        }

        try {
	    const response = await fetch('https://5r4i8qumoe.execute-api.us-east-1.amazonaws.com/prod/user-creds', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${JWTHandler.getToken()}`
                },
                body: JSON.stringify({
                    WalletAddress: userAddress,
                    CredentialType: 'telegram',
                    ApiId: apiId,
                    ApiHash: apiHash,
                    PhoneNumber: phoneNumber
                }),
            });

            const responseData = await response.json();

        if (response.ok) {
            alert('Credentials saved. Please check your Telegram for the 2FA code.');
            await handle2FACodeInput();
        } else {		
                console.error('Complete Auth Error:', responseData);
                alert('Failed to complete authentication. Please try again.');
                // Handle error in completion
            }
        } catch (error) {
            console.error('Network error on completing auth:', error);
            alert('Network error. Please check your connection and try again.');
            // Handle network errors
        }
    };

  return (
    <div className="my-pinnages-page message">
      <h2>My Pinnages [WORK IN PROGRESS]</h2>
      <div className="spacer"></div> {/* This will create the space */}
      <p>To view your latest Telegram group pinned messages:</p>
      <ul>
        <li>Sign up for Telegram using any application.</li>
        <li>Log in to Telegram core: <a href="https://my.telegram.org" target="_blank" rel="noopener noreferrer">https://my.telegram.org</a>.</li>
        <li>Go to "API development tools" and fill out the form.</li>
	<li>Copy the api_id and api_hash parameters and paste them below:</li>
        <li>Click "Save" and wait up to 30 minutes for new pinned messages to aggregate.</li>
        <p>Please note that for the moment, each number can only have one api_id connected to it.</p>
      </ul>
     <WalletConnect 
        ref={walletConnectRef}
        onConnected={handleWalletConnected} 
        onUserAddressSet={setUserAddress} 
        renderButton={false}
      />
      <form onSubmit={handleApiCredentialsSubmit}>
        <input 
          type="password" 
          placeholder="API ID" 
          value={apiId} 
          onChange={(e) => setApiId(e.target.value)} 
        />
        <input 
          type="password" 
          placeholder="API Hash" 
          value={apiHash} 
          onChange={(e) => setApiHash(e.target.value)} 
        />
        <input 
          type="tel" // Use "tel" to bring up numeric keypad on mobile devices
          placeholder="Phone Number" 
          value={phoneNumber} 
          onChange={(e) => setPhoneNumber(e.target.value)} 
          pattern="\+\d{10,14}" // Simple pattern for HTML5 validation, consider more complex patterns based on your needs
          title="Phone number must be in international format (e.g., +12345678900)"
        />
        <p className="phone-number-instructions">
          Use the international format +12345678900
        </p>
	<button type="submit">Save</button>
      </form>
    </div>
  );
};

export default MyPinnages;

import React, { useState, useEffect, useCallback, memo } from 'react';
import { debounce } from './utils.js';

function extractTwitterUrls(pinnedMessage, onlyFirst = true) {
  let processedMessage = pinnedMessage.replace(/<br>/gi, ' ');

  processedMessage = processedMessage.replace(/https:\/\/(fx|vx)twitter\.com\/[\w\d-_.\/?=&]+/g, match => {
    const path = match.split('.com/')[1];
    return `https://twitter.com/${path}`;
  });	

  processedMessage = processedMessage.replace(/https:\/\/x\.com\/[\w\d-_.\/?=&]+/g, match => {
    const path = match.split('x.com/')[1];
    return `https://twitter.com/${path}`;
  });

  const strippedMessage = processedMessage.replace(/<[^>]+>/g, '');
  const urlRegex = /https:\/\/twitter\.com\/[\w\d-_.\/?=&]+/g;
  let urls = strippedMessage.match(urlRegex) || [];
  const spaceRegex = /https:\/\/twitter\.com\/i\/spaces\/[\w\d-_.\/?=&]+/g;
  urls = urls.filter(url => !spaceRegex.test(url));
  urls = urls.map(url => url.split(/\s|<|>/)[0]);

  if (onlyFirst && urls.length > 0) {
    return [urls[0]]; // Return only the first URL
  }

  return urls;
}

const TwitterPreview = memo(({ url }) => {
  const [twitterHtml, setTwitterHtml] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const debouncedFetchTwitterHtml = useCallback(debounce(async (cleanUrl) => {
    if (twitterHtmlCache[cleanUrl]) {
      setTwitterHtml(twitterHtmlCache[cleanUrl]);
      return;
    }

    setIsLoading(true);
    try {
      const lambdaEndpoint = 'https://5j899d5b7h.execute-api.us-east-1.amazonaws.com/prod/twitter';
      const response = await fetch(`${lambdaEndpoint}?url=${encodeURIComponent(cleanUrl)}`);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

      const data = await response.json();
      if (data.body) {
        const bodyJson = JSON.parse(data.body);
        twitterHtmlCache[cleanUrl] = bodyJson.html;
        setTwitterHtml(bodyJson.html);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }, 1000), []);

  useEffect(() => {
    debouncedFetchTwitterHtml(url);
  }, [url, debouncedFetchTwitterHtml]);

  useEffect(() => {
    if (twitterHtml && window.twttr && window.twttr.widgets) {
      window.twttr.widgets.load();
    }
  }, [url, twitterHtml]);

  if (isLoading) return <p>Loading Twitter preview...</p>;
  if (error) return <p>Error loading preview: {error}</p>;

  return (
    <div className="twitter-embed" dangerouslySetInnerHTML={{ __html: twitterHtml }} />
  );
});

const twitterHtmlCache = {};

export { TwitterPreview, extractTwitterUrls };

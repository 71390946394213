import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Web3Provider } from '@ethersproject/providers';
import { shortenAddress } from './utils.js';
import JWTHandler from './JWTHandler.js';

const WalletConnect = forwardRef(({ onConnected, onUserAddressSet, renderButton = true }, ref) => {
  const [isConnecting, setIsConnecting] = useState(false);
  const [walletConnected, setWalletConnected] = useState(false);
  const [userAddress, setUserAddress] = useState(null);

  useEffect(() => {
    const checkConnection = async () => {
      if (window.ethereum && window.ethereum.selectedAddress && !walletConnected) {
        const provider = new Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const address = await signer.getAddress();
        setUserAddress(address);
        setWalletConnected(true);
        onUserAddressSet(address);
        onConnected(provider, signer);
      }
    };

    checkConnection();
  }, [onConnected, onUserAddressSet, walletConnected]);

  const generateUUID = () => {
    const timestamp = Date.now().toString(36);
    const randomString = Math.random().toString(36).substring(2, 15);
    return `${timestamp}-${randomString}`;
  }

  const connectWalletHandler = async () => {
    if (walletConnected || !window.ethereum) {
      return;
    }

    try {
      await window.ethereum.enable();
      setIsConnecting(true);
      const provider = new Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      
      const address = await signer.getAddress();
      setUserAddress(address);
      setWalletConnected(true);
      onUserAddressSet(address);
      onConnected(provider, signer);

      if (!JWTHandler.getToken() || !JWTHandler.validateToken()) {
        await signMessageHandler(address, signer);
      }
    } catch (error) {
      console.error('Connection to wallet failed:', error);
      setWalletConnected(false);
    } finally {
      setIsConnecting(false);
    }
  };

  useImperativeHandle(ref, () => ({
    initiateWalletConnection: () => {
      connectWalletHandler();
    },
  }));

  const signMessageHandler = async (address, signer) => {
    try {
      const nonce = window.crypto.getRandomValues(new Uint8Array(16)).join('');
      const tokenId = generateUUID();
      const message = `I am signing my one-time nonce: ${nonce}`;
      const signature = await signer.signMessage(message);

      const apiEndpoint = 'https://j2flhumci2.execute-api.us-east-1.amazonaws.com/prod/user-session';
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            WalletAddress: address,
            TokenId: tokenId,
            signature: signature 
        }),
      });

      if (!response.ok) {
        throw new Error(`Error from API: ${response.status}`);
      }

      const data = await response.json();
      JWTHandler.storeToken(data.jwtToken);
      setWalletConnected(true);
    } catch (error) {
      console.error('Error during message signing or token retrieval:', error);
      setWalletConnected(false);
    }
  };

  return renderButton ? (
    <div>
      <button onClick={connectWalletHandler} disabled={isConnecting}>
        {userAddress ? shortenAddress(userAddress) : (isConnecting ? 'Connecting...' : 'Connect Wallet')}
      </button>
    </div>
  ) : null;
});

export default WalletConnect;


import React from 'react';
import DOMPurify from 'dompurify';

const PinnedMessage = ({ message }) => {
  const renderPinnedMessage = () => {
//    console.log("Original message:", message);
    if (!message || message.trim().toLowerCase() === 'none') {
        return '';
    }
    const cleanHTML = DOMPurify.sanitize(message, {
      ADD_ATTR: ['target'],
      ADD_TAGS: ['a']
    });
//    console.log("Sanitized HTML:", cleanHTML);
	
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(cleanHTML, 'text/html');
//    console.log("Parsed HTML:", parsedHtml.body.innerHTML);
    const links = parsedHtml.querySelectorAll('a');
    links.forEach(link => link.setAttribute('target', '_blank'));

    return parsedHtml.body.innerHTML;
  };

  return (
    <div
      className="message-content"
      dangerouslySetInnerHTML={{ __html: renderPinnedMessage() }}
    />
  );
};

export default PinnedMessage;


import React, { useState, useEffect, useCallback, memo } from 'react';
import DOMPurify from 'dompurify';
import PinnedMessage from './PinnedMessage.js';
import { shortenAddress } from './utils.js'; // Adjust if necessary
import InfiniteScroll from './InfiniteScroll.js';
import { TwitterPreview, extractTwitterUrls } from './TwitterPreview.js';

const debounce = (func, delay) => {
  let inDebounce;
  return function(...args) {
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(this, args), delay);
  };
};

function Home() {
  const [messages, setMessages] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [start, setStart] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const fetchMessages = useCallback(async () => {
    if (!hasMore || isLoading) return;
    
    setIsLoading(true);
    console.log("Fetching messages, start:", start); // Debugging log
    let searchEndpoint = `https://abvkoqz50j.execute-api.us-east-1.amazonaws.com/prod/messages?start=${start}`;
    try {
      const response = await fetch(searchEndpoint);
      console.log("Response:", response); // Debugging log
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const rawData = await response.text();

      let data = JSON.parse(rawData);
      console.log("Data received:", data); // Debugging log
      const newMessages = data.items.map(item => ({
        groupId: item.fields.groupid ? item.fields.groupid[0] : 'N/A',
        groupName: item.fields.groupname ? item.fields.groupname[0] : 'N/A',
        pinnedMessage: item.fields.pinnedmessage ? item.fields.pinnedmessage[0] : '',
        timestamp: item.fields.timestamp ? item.fields.timestamp[0] : 'N/A',
        MediaURL: item.fields.mediaurl && item.fields.mediaurl.length > 0 ? item.fields.mediaurl[0] : null
      }));

      const sortedMessages = newMessages.sort((a, b) => b.timestamp - a.timestamp);
      setMessages(prevMessages => [...prevMessages, ...newMessages]);
      setStart(data.start);
      setHasMore(newMessages.length === 20);
    } catch (error) {
      setErrorMsg(`Failed to load messages: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [start, hasMore, isLoading]);

  useEffect(() => {
    fetchMessages();
  }, []);

  return (
    <div className="message-container">
      {messages.map((message, index) => (
        <div key={index} className="message">
          <h2 className="message-group">{message.groupName}</h2>
          <PinnedMessage message={message.pinnedMessage} />
          {message.MediaURL ? (
            message.MediaURL.endsWith('.mp4') || message.MediaURL.endsWith('.mov') ?
              <video src={message.MediaURL} controls /> :
            message.MediaURL.endsWith('.ogg') ?
              <audio src={message.MediaURL} controls /> :
            message.MediaURL.endsWith('.jpg') || message.MediaURL.endsWith('.png') ? 
              <a href={message.MediaURL} target="_blank">
                <img src={message.MediaURL} alt="Media" />
              </a> :
              <img src={message.MediaURL} alt="Other Media" />
          ) : (
            extractTwitterUrls(message.pinnedMessage).map((url, idx) =>
              idx === 0 ? <TwitterPreview key={url} url={url} /> : null
            )
          )}
          <p className="message-timestamp">
            {new Date(parseInt(message.timestamp) * 1000).toLocaleString()}
          </p>
        </div>
      ))}
      {errorMsg && <p className="error-message">{errorMsg}</p>}
      {isLoading && <p className="loading-message">Aggregating pinned messages...</p>}
      <InfiniteScroll loadMore={fetchMessages} isLoading={isLoading} />
    </div>
  );
}

export default Home;

// JWTHandler.js
import { jwtDecode } from 'jwt-decode';

const JWTHandler = {
  getToken: () => {
    return localStorage.getItem('jwtToken');
  },

  validateToken: () => {
    const token = JWTHandler.getToken();
    if (!token) {
      return false;
    }

    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        localStorage.removeItem('jwtToken');
        return false;
      }
      return true;
    } catch (error) {
      localStorage.removeItem('jwtToken');
      return false;
    }
  },

  storeToken: (jwtToken) => {
    localStorage.setItem('jwtToken', jwtToken);
  }
};

export default JWTHandler;


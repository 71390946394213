import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import PinnedMessage from './PinnedMessage.js';
import { TwitterPreview, extractTwitterUrls } from './TwitterPreview.js';
import InfiniteScroll from './InfiniteScroll.js';
import './GroupDetails.css';

const GroupDetails = () => {
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [start, setStart] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [error, setError] = useState('');
    const { groupName } = useParams();

    const fetchMessages = useCallback(async () => {
        if (!hasMore || loading) return;
        setLoading(true);
        setError('');

        const url = `https://v0qtncdlyb.execute-api.us-east-1.amazonaws.com/prod/group-details?group=${encodeURIComponent(groupName)}&start=${start}`;
        try {
            const response = await fetch(url);
            if (!response.ok) throw new Error(`API call failed with status: ${response.status}`);

            const data = await response.json();
            setMessages(prevMessages => [
                ...prevMessages,
                ...data.items.map(item => ({
                    ...item,
                    fields: {
                        ...item.fields,
                        pinnedmessage: item.fields.pinnedmessage ? item.fields.pinnedmessage[0] : '', // Add a default empty string
                        timestamp: item.fields.timestamp ? item.fields.timestamp[0] : 'N/A',                        
			mediaurl: Array.isArray(item.fields.mediaurl) && item.fields.mediaurl.length > 0 ? item.fields.mediaurl[0] : null,
                    },
                }))
            ]);
            setStart(prevStart => prevStart + data.items.length);
            setHasMore(data.items.length === 20);
        } catch (error) {
            setError(`Error fetching messages: ${error.message}`);
            setHasMore(false);
        } finally {
            setLoading(false);
        }
    }, [groupName, start, hasMore, loading]);

    useEffect(() => {
        fetchMessages();
    }, [fetchMessages]);

    return (
        <div className="message-container">
            <h1 className="message-group">{groupName}</h1>
            {loading && <p className="loading-message">Loading more messages...</p>}
            {error && <p className="error-message">{error}</p>}
            {messages.map((message, index) => (
                <div key={index} className="message">
                    <PinnedMessage message={message.fields.pinnedmessage} />
                    {message.fields.mediaurl ? (
                        message.fields.mediaurl.endsWith('.mp4') || message.fields.mediaurl.endsWith('.mov') ? 
                            <video src={message.fields.mediaurl} controls className="media" /> :
                        message.fields.mediaurl.endsWith('.jpg') || message.fields.mediaurl.endsWith('.png') ? 
                            // Commented out the old line and added new functionality
                            // <img src={message.fields.mediaurl} alt="Media" className="media" /> :
                            <a href={message.fields.mediaurl} target="_blank">
                              <img src={message.fields.mediaurl} alt="Media" className="media" />
                            </a> :
                            null
                    ) : (
                        extractTwitterUrls(message.fields.pinnedmessage).map((url, idx) =>
                            idx === 0 ? <TwitterPreview key={url} url={url} /> : null
                        )
                    )}
                    <p className="message-timestamp">{new Date(parseInt(message.fields.timestamp) * 1000).toLocaleString()}</p>
                </div>
            ))}
            {hasMore && !loading && <InfiniteScroll loadMore={fetchMessages} isLoading={loading} />}
        </div>
    );
};

export default GroupDetails;

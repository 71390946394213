import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Explore.css'; // Make sure to create and import Explore.css

const Explore = () => {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchGroups = async () => {
      setLoading(true);
      try {
        const response = await fetch('https://5le36tbde6.execute-api.us-east-1.amazonaws.com/prod/explore-groups');

        if (!response.ok) {
          console.error("API response not ok:", response.status);
          throw new Error(`API call failed with status: ${response.status}`);
        }

        const data = await response.json();
        if (data && data.body) {
          const body = JSON.parse(data.body);
          if (body.groups) {
            setGroups(body.groups);
          } else {
            console.error("Groups data not found in response:", body);
          }
        } else {
          console.error("Invalid data structure received:", data);
        }
      } catch (error) {
        console.error("Error fetching groups:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchGroups();
  }, []);

  if (loading) {
    return <p className="loading-message">Aggregating group information...</p>; // Updated loading message
  }

  return (
    <div className="explore-container">
      <h1 className="explore-header">Explore Groups</h1>
      <div className="groups-list">
        {groups.map((group, index) => (
          <div key={index} className="group-card">
            <Link to={`/group/${encodeURIComponent(group)}`} className="group-link">{group}</Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Explore;


import React, { useEffect, useCallback } from 'react';

export const debounce = (func, delay) => {
  let inDebounce;
  const debouncedFunction = function(...args) {
    const context = this;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };

  debouncedFunction.cancel = function() {
    clearTimeout(inDebounce);
  };

  return debouncedFunction;
};

const InfiniteScroll = ({ loadMore, isLoading }) => {
  const handleScroll = useCallback(() => {
    if ((window.innerHeight + document.documentElement.scrollTop < document.documentElement.offsetHeight - 100) || isLoading) return;
    loadMore();
  }, [isLoading, loadMore]);

  useEffect(() => {
    const debouncedScrollHandler = debounce(handleScroll, 300);
    window.addEventListener('scroll', debouncedScrollHandler);
    return () => {
      debouncedScrollHandler.cancel(); // Make sure to cancel any debounced calls
      window.removeEventListener('scroll', debouncedScrollHandler);
    };
  }, [handleScroll]);

  return null; // This component doesn't render anything itself
};

export default InfiniteScroll;

